<template>
  <div>
    <div class="filter-panel">
      <CSSelect style="border-radius: 4px; width: 210px; margin-right: 0;">
        <el-date-picker
            v-model="queryParams.startTime"
            type="datetime"
            style="width: 182px;"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择开始时间"
            :editable="false"
        ></el-date-picker>
      </CSSelect>
      <span class="inline-gray"></span>
      <CSSelect style="border-radius: 4px; width: 210px;">
        <el-date-picker
            v-model="queryParams.endTime"
            type="datetime"
            style="width: 182px;"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择结束时间"
            :editable="false"
        ></el-date-picker>
      </CSSelect>
      <input type="text" style="border-radius: 4px;" class="cs-input" v-model="queryParams.keyword"
             placeholder="搜索公告名称"/>
      <CSSelect style="margin-right: 30px; border-radius: 4px;">
        <select
            v-model="queryParams.state"
            style="padding-left: 5px;"
        >
          <option value="">公告状态不限</option>
          <option :value="1">待展示</option>
          <option :value="2">展示中</option>
            <option :value="3">结束展示(已下架)</option>
        </select>
      </CSSelect>
      <button class="btn btn-primary" style="width: 50px; height: 30px; padding: 0;"
              @click="getAnnouncementList()">查询
      </button>
    </div>
    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button class="btn btn-primary sticky-right" @click="openAnnouncementDialog">
              <svg
                  class="icon"
                  aria-hidden="true"
              >
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              添加公告
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>添加时间</th>
            <th>公告名称</th>
            <th>展示位</th>
            <th>发布时间</th>
            <th>下架时间</th>
            <th>公告状态</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="announcementList && announcementList.length > 0">
            <tr v-for="item in announcementList" :key="item.id">
              <td class="date-td">{{ item.createTime }}</td>
              <td><span class="allow-click"
                        @click="lookAnnouncement(item.id, item.displayPosition)">{{ item.name }}</span>
              </td>
              <td>{{ item.displayPosition == '1' ? '平板' : '壁挂显示屏' }}</td>
              <td>{{ item.availableTime }}</td>
              <td>{{ item.invalidTime }}</td>
              <td>{{ announcementStates[item.state] }}</td>
              <td>
                <div class="btn-group">
                  <button
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu">
                    <li v-if="item.state === 1" @click="changeEnableStatus(item.id, false)">
                      <a style="width: 100%;">
                        下架
                      </a>
                    </li>
                    <li v-else @click="changeEnableStatus(item.id, true)">
                      <a style="width: 100%;">
                        上架
                      </a>
                    </li>
                    <li @click="editAnnouncementDialog(item)">
                      <a style="width: 100%;">
                        修改
                      </a>
                    </li>
                    <li @click="deleteAnnouncement(item.id)">
                      <a style="width: 100%;">
                        删除公告
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <Pagination/>
    </div>

    <CSDialog :dialog-visible="announcementDialog.visible" dialog-width="916px"
              :dialog-title="announcementDialog.title"
              dialog-confirm-btn-text="预览" @onClose="announcementDialog.onCancel"
              @onConfirm="announcementDialog.onConfirm"
    >
      <template v-slot:dialog-content>
        <div class="form-panel">
          <div class="form-item">
            <div class="form-item-label">公告名称</div>
            <div class="form-item-content">
              <input type="text" placeholder="限20个字" maxlength="20" style="width: 400px;"
                     class="cs-input" v-model="announcementDialog.data.name"/>
            </div>
          </div>
          <div class="form-item">
            <div class="form-item-label">展示位</div>
            <div class="form-item-content">
              <CSSelect height="40px" i-width="36px">
                <select v-model="announcementDialog.data.displayPosition" name="" id=""
                        style="width: 288px; padding-left: 10px;color: #999999">
                  <option value="">请选择</option>
                  <option :value="index" v-for="(displayPosition, index) in displayPositionState"
                          :key="displayPosition">{{ displayPosition }}
                  </option>
                </select>
              </CSSelect>
            </div>
          </div>
          <div class="form-item">
            <div class="form-item-label">选择面板组</div>
            <div class="form-item-content">
              <CSSelect height="40px" i-width="36px">
                <select @change="changeDeviceManage" v-model="announcementDialog.data.groupId"
                        style="width: 288px; padding-left: 10px;color: #999999">
                  <option value="" disabled>请选择</option>
                  <option v-for="item in deviceManageList"
                          :key="item.id" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </CSSelect>
            </div>
          </div>
          <div class="form-item">
            <div class="form-item-label">发布时间</div>
            <div class="form-item-content">
              <CSSelect height="40px" i-width="36px">
                <el-date-picker
                    type="datetime"
                    style="width: 264px;"
                    v-model="announcementDialog.data.availableTime"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm"
                    placeholder="请选择"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
              </CSSelect>
            </div>
          </div>
          <div class="form-item">
            <div class="form-item-label">下架时间</div>
            <div class="form-item-content">
              <CSSelect height="40px" i-width="36px">
                <el-date-picker
                    type="datetime"
                    style="width: 264px;"
                    v-model="announcementDialog.data.invalidTime"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm"
                    placeholder="请选择"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
              </CSSelect>
            </div>
          </div>
          <div class="form-item">
            <div class="form-item-label top">公告内容</div>
            <div class="form-item-content">
              <div style="max-width: 690px;">
                <div class="photo-view"
                     v-for="(resourceItem, index) in announcementDialog.data.resources" :key="index"
                     :style="{width: '200px', height: '128px', marginBottom: announcementDialog.data.resources.length > 2 ? '10px' : '0'}"
                     draggable @dragover="fileDragOver(index, $event)"
                     @drop="fileDragEnd(index, $event)"
                >
                  <img v-if="resourceItem.type === 1" :src="resourceItem.url" alt=""
                       @dragstart="fileDragStart(index, $event)">
                  <video oncanplay="settingVolume"  ref="localVideo" v-else :src="resourceItem.url" muted autoplay="autoplay" loop="loop"
                         @dragstart="fileDragStart(index, $event)" style="width: 200px;height: 128px;"></video>
                  <i class="icon-close" @click="deleteFile(index)"/>
                </div>
                <div class="upload-photo" style="width: 200px;height: 128px;"
                     v-if="announcementDialog.data.resources.length < 20" @click="triggerChooseFile"
                >
                  <div class="text-center">
                    <img src="@/assets/upload.png" style="margin: 5px auto 5px"/>
                    <p style="color: #999">
                      {{ announcementDialog.data.displayPosition === '1' ? '上传图片' : (announcementDialog.data.displayPosition != '' ? '上传图片/视频' : '')}}
                    </p>
                    <p style="color: #999">
                      {{ announcementDialog.data.displayPosition !== '2' ? '尺寸800*512' : '请按照屏幕尺寸上传' }}
                    </p>
                  </div>
                </div>
              </div>
              <div style="margin-top: 17px;">
                停留
                <input type="number" v-model="announcementDialog.data.duration" class="cs-input"
                       style="width: 180px;" placeholder="限整数3-300" maxlength="3">
                秒
              </div>
              <input type="file" id="uploadFile"
                     accept="image/gif, image/jpeg, image/png, video/mp4, audio/mp4, video/3gpp, audio/3gpp, video/avi"
                     hidden
                     @change="chooseFile($event)">
            </div>
          </div>

          <!--                    <div class="form-tips">-->
          <!--                        <p>注：</p>-->
          <!--                        <p>1、拖动图片可调换顺序</p>-->
          <!--                        <p>2、面板屏幕分辨率：{{deviceResolution}}。</p>-->
          <!--                        <p>3、图片在轮播中，停留x秒，自动播放下一个图片。(面板中只有一个图片，就一直展示)</p>-->
          <!--                    </div>-->
        </div>
      </template>
    </CSDialog>

    <AnnouncementPreview :visible="announcementPreviewDialog.visible">
      <template v-slot:announcementContent>
        <div style="width: 324px;height:246px;background: #fff;">
          <el-carousel trigger="click" height="246px">
            <el-carousel-item v-for="(item, index) in announcementPreviewDialog.data" :key="index">
              <img v-if="item.type === 1" :src="item.url" alt="" style="width:100%;height:100%;">
              <video v-else :src="item.url" autoplay="autoplay" muted loop="loop"
                     style="width:100%;height:100%;"></video>
            </el-carousel-item>
          </el-carousel>
        </div>
      </template>
      <template v-slot:btnGroup>
        <template v-if="statePreview">
          <button class="btn btn-primary" style="width:180px;height:40px;font-size:20px;margin-right: 50px;"
                  @click="announcementPreviewDialog.prevStep">上一步
          </button>
          <button class="btn btn-primary" style="width:180px;height:40px;font-size:20px;"
                  @click="announcementPreviewDialog.onConfirm">提交
          </button>
        </template>
        <button v-else
                style="display:block; width: 140px; height:40px; line-height: 20px; font-size:20px; margin: 0 auto;"
                class="btn btn-primary" @click="announcementPreviewDialog.onClose">关闭
        </button>
      </template>
    </AnnouncementPreview>
    <Monitor :monitorVisible="announcementPreviewDialog.monitorVisible">
      <template v-slot:monitorContent>
        <div style="width: 644px; height:380px; position: absolute; top: 14px; left:14px; background: #fff;">
          <el-carousel trigger="click" height="380px">
            <el-carousel-item v-for="(item, index) in announcementPreviewDialog.data" :key="index">
              <img v-if="item.type === 1" :src="item.url" alt="" style="width:100%;height:100%;">
              <video v-else :src="item.url" autoplay="autoplay" muted loop="loop"
                     style="width:100%;height:100%;"></video>
            </el-carousel-item>
          </el-carousel>
        </div>
      </template>
      <template v-slot:btnGroup>
        <template v-if="statePreview">
          <button class="btn btn-primary" style="width:180px;height:40px;font-size:20px;margin-right: 50px;"
                  @click="announcementPreviewDialog.prevStep">上一步
          </button>
          <button class="btn btn-primary" style="width:180px;height:40px;font-size:20px;"
                  @click="announcementPreviewDialog.onConfirm">提交
          </button>
        </template>
        <button v-else
                style="display:block; width: 140px; height:40px; line-height: 20px; font-size:20px; margin: 0 auto;"
                class="btn btn-primary" @click="announcementPreviewDialog.onClose">关闭
        </button>
      </template>
    </Monitor>
    <div class="layer" v-if="speedProgressVisible">
      <div
          style="position: absolute; top: 50%; left: 50%; font-size: 18px; color: #fff; transform: translate(-50%, -50%);">
        <div class="rotate-out-center">
        </div>
        <div>
          已上传{{ speedProgress + "%" }}，请耐心等待...
        </div>
        <div style="text-align: center;">
          <button
              type="button"
              class="btn btn-primary"
              style="margin-top: 20px;"
              @click="cancelUpData"
          >
            取消上传
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination";
import CSDialog from "@/components/common/CSDialog";
import AnnouncementPreview from "@/components/phonePreview/AnnouncementPreview";
import Monitor from "@/components/Monitor";
import dayjs from "dayjs";
import {
  queryAdvertiseConfigUrl,
  queryGroup,
  queryDevice,
  addAdvertiseConfigUrl,
  updateConfigEnableUrl, deleteAdvertiseConfigEnableUrl, queryAdvertiseResourcesUrl, editAdvertiseConfigUrl,
  commonImgOssDomain,
  downloadFileOssDomain, queryDeviceAdvertisingCountUrl,
} from '@/requestUrl'
import CSTable from "@/components/common/CSTable";

export default {
  name: "TurnstileAnnouncement",
  components: {
    CSTable,
    CSSelect,
    Pagination,
    CSDialog,
    AnnouncementPreview,
    Monitor,
  },
  created() {
    window.addEventListener("keydown", this.getAnnouncementListDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.getAnnouncementListDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.getAnnouncementListDown);
  },
  data() {
    return {
      statePreview: false,
      filterHeight: 0,
      queryParams: {
        startTime: dayjs().subtract(30, 'day').format("YYYY-MM-DD HH:mm"),
        endTime: dayjs().format("YYYY-MM-DD") + ' 23:59',
        keyword: "",
        state: ''
      },
      advertisingCount: 0,
      announcementStates: {
        0: '初始',
        1: '展示中',
        2: '结束展示(到下架时间)',
        3: '已下架',
        4: '被其他公告替代结束展示'
      },
      displayPositionState: {
        1: '平板',
        2: '壁挂显示屏'
      },
      announcementList: [],
      deviceManageList: [],
      deviceResolution: '-',
      announcementPreviewDialog: {
        visible: false,
        monitorVisible: false,
        data: [],
        prevStep: () => {
          this.announcementPreviewDialog.visible = false;
          this.announcementPreviewDialog.monitorVisible = false;
          this.announcementDialog.visible = true;
          this.statePreview = false;
        },
        onConfirm: () => {
        },
        onClose: () => {
          this.announcementPreviewDialog.visible = false;
          this.announcementPreviewDialog.monitorVisible = false;
          this.statePreview = false;
        }
      },
      announcementDialog: {
        visible: false,
        title: '添加公告',
        data: {
          name: '',
          displayPosition: '',
          groupId: '',
          availableTime: '',
          invalidTime: '',
          duration: '',
          resources: []
        },
        onCancel: () => {
          this.announcementDialog.visible = false;
        },
        onConfirm: () => {
          if (this.announcementDialog.data.resources.length === 0) {
            this.$vc.toast('请上传配图');
            return;
          }
          this.announcementPreviewDialog.data = this.announcementDialog.data.resources;
          this.statePreview = true;
          if (this.announcementDialog.data.displayPosition == 1) {
            this.announcementPreviewDialog.visible = true;
          } else {
            this.announcementPreviewDialog.monitorVisible = true;
          }
          this.announcementDialog.visible = false;
        }
      },
      ossClient: {},
      speedProgressVisible: false,
      speedProgress: 0,
    }
  },
  mounted() {
    this.getAnnouncementList();
    this.queryDeviceManageList();
    this.$vc.on(this.$route.path, 'pagination_page', 'event', page => {
      this.getAnnouncementList(page);
    })
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    window.addEventListener('resize', () => {
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    })
  },
  methods: {
    settingVolume(){
      this.$refs.localVideo.volume = 0;
    },
    getAnnouncementListDown(e) {
      if (e.keyCode == 13) {
        this.getAnnouncementList();
      }
    },
    /**
     * 文件位置拖拽结束
     * @param {Number} index 文件原本所在下标
     * @param {DragEvent}  e 文件拖拽事件对象
     * */
    fileDragEnd(index, e) {
      const dragStartIndex = Number(e.dataTransfer.getData("text/plain")),
          {resources} = this.announcementDialog.data;
      if (dragStartIndex < index) {
        resources.splice(index + 1, 0, resources[dragStartIndex]);
        resources.splice(dragStartIndex, 1);
      }
      if (dragStartIndex > index) {
        resources.splice(index, 0, resources[dragStartIndex]);
        resources.splice(dragStartIndex + 1, 1);
      }
      e.dataTransfer.clearData("text/plain");
    },
    /**
     * 文件位置拖拽开始
     * @param {Number} index 文件原本所在下标
     * @param {DragEvent}  e 文件拖拽事件对象
     * */
    fileDragStart(index, e) {
      e.dataTransfer.setData('text/plain', index.toString());
    },
    /**
     * 监听文件拖拽到可放置区域结束
     * @param {Number} index 文件原本所在下标
     * @param {DragEvent}  e 文件拖拽事件对象
     * */
    fileDragOver(index, e) {
      e.preventDefault();
    },
    async lookAnnouncement(id, displayPosition) {
      const resources = await this.queryAnnouncementDetail(id);
      this.statePreview = false;
      this.announcementPreviewDialog.data = resources;
      if (displayPosition == 1) {
        this.announcementPreviewDialog.visible = true;
      } else {
        this.announcementPreviewDialog.monitorVisible = true;
      }
    },
    /**
     * 查询公告详情
     * @param {Number} configId 公告id
     * */
    queryAnnouncementDetail(configId) {
      return this.$fly.get(queryAdvertiseResourcesUrl, {
        configId
      })
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            return res.data || [];
          })
    },
    clearFormData() {
      this.announcementDialog.data = {
        name: '',
        displayPosition: '',
        groupId: '',
        availableTime: '',
        invalidTime: '',
        duration: '',
        resources: []
      };
    },
    /**
     * 删除公告
     * @param {Number} id 公告id
     * */
    deleteAnnouncement(id) {
      this.$CSDialog.confirm({
        title: '提示',
        message: `确定删除吗？`,
        onConfirm: () => {
          this.$fly.post(deleteAdvertiseConfigEnableUrl, {
            id,
          })
              .then(res => {
                if (res.code !== 0) {
                  return;
                }
                this.$vc.toast('删除成功');
                this.getAnnouncementList();
                this.$CSDialog.instance.closeDialog();
              })
        }
      })
    },
    /**
     * 更改公告展示状态
     * @param {Number} id 公告id
     * @param {Boolean} enable 上下架状态 true: 启用  false: 禁用
     * */
    changeEnableStatus(id, enable) {
      this.$CSDialog.confirm({
        title: '提示',
        message: `确定${enable ? '上' : '下'}架吗？`,
        onConfirm: () => {
          this.$fly.post(updateConfigEnableUrl, {
            id,
            enable
          })
              .then(res => {
                if (res.code !== 0) {
                  return;
                }
                this.$vc.toast('操作成功');
                this.getAnnouncementList();
                this.$CSDialog.instance.closeDialog();
              })
        }
      })
    },
    /**
     * 删除选中的文件资源
     * @param {Number} index 文件路径所在下标
     * */
    deleteFile(index) {
      this.announcementDialog.data.resources.splice(index, 1);
    },
    // 打开编辑弹窗
    async editAnnouncementDialog(announcement) {
      this.announcementDialog.title = '修改公告';
      const resources = await this.queryAnnouncementDetail(announcement.id);
      this.announcementDialog.data = {
        name: announcement.name,
        displayPosition: announcement.displayPosition,
        groupId: announcement.groupId,
        availableTime: announcement.availableTime,
        invalidTime: announcement.invalidTime,
        duration: announcement.duration,
        resources,
      };
      this.announcementPreviewDialog.onConfirm = () => {
        if (this.advertisingCount + this.announcementDialog.data.resources.length < 2) {
          this.$vc.message('至少选择两个广告');
          return;
        }
        this.$fly.post(editAdvertiseConfigUrl, {
          id: announcement.id,
          ...this.announcementDialog.data,
          regionCode: this.$vc.getCurrentRegion().code,
        })
            .then(res => {
              if (res.code !== 0) {
                return;
              }
              this.$vc.toast('修改成功');
              this.clearFormData();
              this.announcementPreviewDialog.visible = false;
              this.announcementPreviewDialog.monitorVisible = false;
              this.getAnnouncementList();
            })
      }
      this.announcementDialog.visible = true;
    },
    // 打开添加公告弹窗
    openAnnouncementDialog() {
      this.announcementDialog.title = '添加公告';
      this.announcementDialog.data = {
        name: '',
        displayPosition: '',
        groupId: '',
        availableTime: '',
        invalidTime: '',
        duration: '',
        resources: []
      }
      this.announcementPreviewDialog.onConfirm = () => {
        if (this.advertisingCount + this.announcementDialog.data.resources.length < 2) {
          this.$vc.message('至少选择两个广告');
          return;
        }
        this.$fly.post(addAdvertiseConfigUrl, {
          ...this.announcementDialog.data,
          regionCode: this.$vc.getCurrentRegion().code,
        })
            .then(res => {
              if (res.code !== 0) {
                return;
              }
              this.$vc.toast('添加成功');
              this.clearFormData();
              this.announcementPreviewDialog.visible = false;
              this.announcementPreviewDialog.monitorVisible = false;
              this.getAnnouncementList();
            })
      }
      this.announcementDialog.visible = true;
    },

    //  调起选择文件
    triggerChooseFile() {
      $("#uploadFile").trigger("click");
    },
    /**
     * 获取文件的宽高
     * @param {Object} file 文件对象
     * @return {Array} [width, height]
     * */
    getFileWidthAndHeight(file) {
      return new Promise((resolve => {
        if (file.type.includes('image')) {
          const imgEl = document.createElement('img');
          imgEl.src = URL.createObjectURL(file);
          imgEl.onload = () => {
            document.body.appendChild(imgEl);
            document.body.removeChild(imgEl);
            resolve([imgEl.width, imgEl.height]);
          }
        } else {
          const videoEl = document.createElement('video');
          videoEl.src = URL.createObjectURL(file);
          videoEl.preload = 'metadata';
          videoEl.hidden = true;
          videoEl.onloadedmetadata = () => {
            document.body.appendChild(videoEl);
            document.body.removeChild(videoEl);
            resolve([videoEl.videoWidth, videoEl.videoHeight]);
          }
        }
      }))
    },
    // 选择文件后处理
    async chooseFile(event) {
      let file = event.target.files[0];
      if (file.size > 300 * 1024 * 1024) {
        this.$vc.toast("最大上传300MB视频，请重新上传。");
        return;
      }
      if (!file.type.includes('image') && !file.type.includes('video')) {
        this.$vc.toast("只可上传图片和视频");
        return false;
      }
      this.speedProgressVisible = true
      file.needEncodeURI = true;
      if (file.type.includes('image')){
        let formData = new FormData(); //新建FormData对象
        formData.append("files", file);
        formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
        formData.append("compress", "true");
        const photo = await this.Constants.uploadFileOne(formData);
        if (photo) {
          this.announcementDialog.data.resources.push({
            url: this.Constants.BUCKET_NAMES.IMG.visitUrl + photo,
            type: file.type.includes('image') ? 1 : 2,
          })
        }
        this.speedProgressVisible = false;

      }else {
        this.$fly
                .putFile(file, (ossObject) => {
                  this.speedProgress = ossObject.progress;
                  this.ossClient = ossObject.ossClient;
                })
                .then(res => {
                  this.speedProgressVisible = false;
                  if (file.size / 1024 / 1024 > 100) {
                    res.url = res.url.replace('http://cosmoss-img.oss-cn-beijing.aliyuncs.com/', commonImgOssDomain);
                  }
                  this.announcementDialog.data.resources.push({
                    url: res.url,
                    type: file.type.includes('image') ? 1 : 2,
                  })
                },)
      }
      event.target.value = "";
    },
    // 更改选中的面板组
    async changeDeviceManage(e) {
      const resolutionMapping = {
        1: '800*1280',
      };
      const advertisingRes = await this.$fly.get(queryDeviceAdvertisingCountUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        groupId: e.target.value,
      });
      this.advertisingCount = advertisingRes.data ?? 0;
      const deviceDetail = await this.queryDeviceList(+e.target.value);
      if (deviceDetail !== undefined) {
        this.deviceResolution = resolutionMapping[deviceDetail.resolution] || '-';
      }
    },
    /**
     * 查询面板(用于查询面板的分辨率)
     * @param {Number} groupId 面板组id
     * */
    queryDeviceList(groupId) {
      return this.$fly.post(queryDevice, {
        regionCode: this.$vc.getCurrentRegion().code,
        keyword: '',
        //type: 4,   // 1：通行闸机园区入；2：通行闸机出；3：会议室入；4：广告面板；5：员工考勤面板；6：通行闸机楼栋入
        pageNo: 1,
        pageSize: 10,
        groupId
      })
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            return res.data[0];
          })
    },
    // 获取面板组
    queryDeviceManageList(pageNo = 1, pageSize = 10) {
      this.$fly.post(queryGroup, {
        regionCode: this.$vc.getCurrentRegion().code,
        keyword: '',
        pageNo,
        pageSize
      })
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            this.deviceManageList = res.data.datas;
          })
    },
    // 获取公告列表
    getAnnouncementList(pageNo = 1, pageSize = 10) {
      this.$fly.post(queryAdvertiseConfigUrl, {
        ...this.queryParams,
        state: this.queryParams.state === '' ? 0 : this.queryParams.state,
        pageNo,
        regionCode: this.$vc.getCurrentRegion().code,
        pageSize,
      })
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            this.announcementList = res.data.datas;
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                currentPage: pageNo,
                pageSize,
              });
            }
          })
    },
    // 取消上传
    cancelUpData() {
      this.ossClient.terminationUpload();
      this.speedProgressVisible = false;
      this.speedProgress = 0;
    },
  }
}
</script>

<style lang="stylus" scoped>
.form-panel
  padding 27px 30px 30px 30px

  .form-item
    margin-bottom 25px
    font-size 0

    &-label
      text-align right
      width 120px
      display inline-block
      margin-right 40px
      vertical-align middle
      font-size 24px

      &.top
        vertical-align top

    &-content
      display inline-block
      vertical-align middle
      font-size 24px

      .cs-input
        &::placeholder
          color #999
        padding-left 10px
        border-radius 4px
        margin 0
        border 1px solid #979797

      /deep/ .el-input__inner
        font-size 24px

        &::placeholder
          color #999

      /deep/ .el-input__prefix
        font-size 24px
        line-height 20px

  .form-tips
    color #999
    font-size 20px

    p
      margin-bottom 0

.rotate-out-center
  position relative
  top 50%
  left 50%
  transform translate(-50%, -50%)
  border-top 5px solid #fff
  border-radius 50%
  width 70px
  height 70px
  animation rotate-out-center 0.8s cubic-bezier(0.550, 0.085, 0.680, 0.530) both infinite

@keyframes rotate-out-center
  0%
    transform translate(-50%, -50%) rotate(0)
  100%
    transform translate(-50%, -50%) rotate(360deg)
</style>
